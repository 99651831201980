module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0485476c63573d18f4ca7cf97015e42d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"closeTimeoutMS":500,"style":{"overlay":{"position":"fixed","top":0,"left":0,"bottom":0,"right":0,"backgroundColor":"rgba(255, 255, 255, 0.25)","zIndex":"1000","width":"100%","height":"100%"},"content":{"position":"absolute","border":"none","top":"20%","right":"0","left":"50%","bottom":0,"maxWidth":"1920px","transform":"translateX(-50%)","borderTop":"1px solid #000","marginLeft":"auto","marginRight":"auto","background":"#fff","overflow":"auto","borderRadius":"0px","backgroundColor":"white","WebkitOverflowScrolling":"touch","outline":"none","padding":"33px","width":"100%"}},"contentLabel":"Modal"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-2YVDV6JP49","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
