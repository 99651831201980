// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-collage-js": () => import("./../../../src/templates/collage.js" /* webpackChunkName: "component---src-templates-collage-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landing-page-aw-21-drop-landing-js": () => import("./../../../src/templates/LandingPage/aw21DropLanding.js" /* webpackChunkName: "component---src-templates-landing-page-aw-21-drop-landing-js" */),
  "component---src-templates-landing-page-aw-21-landing-js": () => import("./../../../src/templates/LandingPage/aw21Landing.js" /* webpackChunkName: "component---src-templates-landing-page-aw-21-landing-js" */),
  "component---src-templates-landing-page-aw-22-landing-js": () => import("./../../../src/templates/LandingPage/aw22Landing.js" /* webpackChunkName: "component---src-templates-landing-page-aw-22-landing-js" */),
  "component---src-templates-landing-page-ss-22-landing-js": () => import("./../../../src/templates/LandingPage/ss22Landing.js" /* webpackChunkName: "component---src-templates-landing-page-ss-22-landing-js" */),
  "component---src-templates-landing-page-ss-23-landing-js": () => import("./../../../src/templates/LandingPage/ss23Landing.js" /* webpackChunkName: "component---src-templates-landing-page-ss-23-landing-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

