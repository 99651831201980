const sassVars = require("!!sass-variables-loader!../styles/variables.scss") // import the variables from scss

export default {
  APP_START: "APP_START",
  // CHANGE_COLLECTION: "CHANGE_COLLECTION",
  CHANGE_COLLECTION_AW21: "CHANGE_COLLECTION_AW21",
  CHANGE_COLLECTION_AW21_DROP: "CHANGE_COLLECTION_AW21_DROP",
  OPEN_LIVESTREAM: "OPEN_LIVESTREAM",
  CLOSE_LIVESTREAM: "CLOSE_LIVESTREAM",
  LOGIN: 'LOGIN',
  SSOLOGIN: 'SSOLOGIN',
  CUSTOMER: 'CUSTOMER',
  UPDATE_LIVESTREAM_URL: 'UPDATE_LIVESTREAM_URL',
  MENU_TO_BLACK: 'MENU_TO_BLACK',

  MQ_XSMALL: parseInt(sassVars.MQ_XSMALL, 10),
  MQ_SMALL: parseInt(sassVars.MQ_SMALL, 10),
  MQ_MEDIUM: parseInt(sassVars.MQ_MEDIUM, 10),
  MQ_LARGE: parseInt(sassVars.MQ_LARGE, 10),
  MQ_XLARGE: parseInt(sassVars.MQ_XLARGE, 10),
  MQ_XXLARGE: parseInt(sassVars.MQ_XXLARGE, 10),

  MOBILE: "mobile",
  DESKTOP: "desktop",

  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",

  AW21: "AW21",
  AW21_DROP: "AW21_drop",

  AW21_ICONIC: "AW21_iconic",
  AW21_CORE: "AW21_core",
  AW21_BRAND: "AW21_brand",
  AW21_DROP_ALPHABET: "AW21_drop_alphabet",
  AW21_DROP_BRAND: "AW21_drop_brand",

  // Style Classes
  FORM_INPUT: `h-14 border px-6 text-black placeholder-black placeholder-opacity-50 focus:placeholder-opacity-100 border-black bg-[#C8D94C] block w-full rounded-md`,
  ICON_BUTTON: `inline-flex items-center px-3 py-3 lg:p-3 border border-transparent rounded-md bg-black outline-none`,
  SIMPLE_LINK: `border-b-2 border-black`,
  ROUNDED_BUTTON: `border-[2px] inline-flex items-center border rounded-full`,

  landingOverallPadding: `lg:px-7`,
  scrollingToEasing: t => {
    return 1 + --t * t * t * t * t
  },

  POST_OPTIONS: { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf-8' } },

  // Font Sizes
  h1: ``,
  h2: `text-3xl lg:!leading-none lg:text-6xl xl:text-7xl 2xl:text-8xl`,
  h3: `text-2xl lg:text-6xl xl:text-7xl 2xl:text-8xl`,
  h4: `text-[14px] lg:text-[28px]`,
  h5: `leading-none text-4xl lg:text-6xl xl:text-7xl 2xl:text-8xl`,
  quote: `text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl`,
  paragraph: `text-[18px] lg:text-xl 2xl:text-2xl`,
  caption: `leading-tight text-[16px] lg:text-[16px]`,
  heading: `text-xl md:text-2xl xl:text-4xl`,
  button: `!text-[14px] lg:!text-[22px]`,
  attribution: `leading-4 text-xs md:text-base 2xl:text-xl`,

  TEXTS: {
    CONNECT_EXPERIENCE: `This experience is exclusive for selected clients and buyers, access it with the password that you’ve received in your e-mail.`,
    YOUR_EMAIL: `Your E-mail`,
    PASSWORD: `Password`,
    EMAIL_HREF: `mailto:DK-HQ-Events@Ecco.com`,
    BRAND_HELP_EMAIL: `mailto:NL-Dongen-ELU@Ecco.com`,
    CONTACT_HREF: `mailto:NL-Dongen-ELU@Ecco.com`,
    TEL_HREF: `tel:877-240-2365`,
    INSTAGRAM_HREF: `https://instagram.com`,
    SPOTIFY_HREF: `https://spotify.com`,
  },
  S3: `https://ile-cp.s3.eu-central-1.amazonaws.com/elu/videos/`,
  API_URL: `https://eccocp.com/api/`,
  SSO: {
    auth: {
      redirectUri: 'https://eccoleathergoodsunit.com/auth/login-redirect',
      authority: 'https://login.microsoftonline.com/06152121-b4c5-4544-abf5-9268e75db448',
      clientId: 'e5556802-b07a-4b40-8307-ce34266db783',
    }
  },
  BASE_URL:
    process.env.GATSBY_ENV === "development"
      ? `${
          typeof window !== `undefined` && window.location
            ? window.location.protocol
            : `http:`
        }//localhost:8020/`
      : process.env.GATSBY_ENV === "production"
      ? `https://eccoleathergoodsunit.com/`
      : `https://distracted-nobel-66d183.netlify.app/`,
  FUNCTIONS_URL:
    process.env.GATSBY_ENV === "development"
      ? `${
          typeof window !== `undefined` && window.location
            ? window.location.protocol
            : `http:`
        }//localhost:8888/`
      : (process.env.GATSBY_ENV === "production"
          ? `https://eccoleathergoodsunit.com/`
          : `https://distracted-nobel-66d183.netlify.app/`) +
        `.netlify/functions/`,
}
