import "./src/styles/index.scss"
import "intersection-observer"
import wrapWithProvider from "./src/wrap-with-provider";
export const wrapRootElement = wrapWithProvider;

// import React, {Component, useEffect} from 'react';
// import useResizeObserver from "use-resize-observer";

// This only works if in the modal page you add a
// Link to the homepage.

export const onPrefetchPathname = ({loadPage}) => {
    const userAgent =
        typeof window.navigator === "undefined" ? "" : navigator.userAgent

    const mobile = () => {
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
            return true
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                userAgent
            )
        ) {
            return true
        }

        if (window.innerWidth < 1024) return true;

        return false;
    }

    if (window.isMobile === undefined) {
        loadPage("/catalogue").then(() => {
            window.isMobile = mobile();

            if (window.setIsMobile) window.setIsMobile();
        })
    }
}

export const onClientEntry = () => {
    if (typeof window.IntersectionObserver === `undefined`) {
        require(`intersection-observer`)
    }
    Object.defineProperty(HTMLMediaElement.prototype, "playing", {
        get: function () {
            return !!(
                this.currentTime > 0 &&
                !this.paused &&
                !this.ended &&
                this.readyState > 2
            )
        },
    })
}

// export const wrapPageElement = ({ element, props }) => {
//
//     const ref = React.createRef();
//     const {height} = useResizeObserver({ref: ref});
//
//     useEffect(() => {
//         //console.log('h', height);
//         window.scroll?.update();
//     }, [height])
//
//     return <div ref={ref}>{element}</div>
// }
