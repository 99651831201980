import React from "react";
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import constants from "./constants";

const msalInstance = new PublicClientApplication(constants.SSO);

export default ({element}) => {
    return (
        <MsalProvider instance={msalInstance}>
            {element}
        </MsalProvider>
    );
}
